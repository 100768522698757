'use client';

import { cn } from '@/lib/utils';
import { Pencil } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
type InstantFieldProps<T> = {
  defaultValue: T;
  /**
   * Function that will change the value in the backed.
   * Returns the new value, throws an error on error.
   *
   * @param v New value
   * @returns
   */
  updateAction: (v: T) => Promise<T>;
  placeholder?: string;
  label?: string;
  vertical?: boolean;
  className?: string;
  /**
   * Should return an error message if the value is invalid.
   */
  validate?: (value: T) => Promise<string | null>;
  onBlur?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
};
export function InstantTextAreaField({
  autoexpand,
  defaultValue,
  placeholder,
  className,
  validate,
  updateAction,
  onBlur,
  // Not implemented disabled
  disabled
}: InstantFieldProps<string> & {
  autoexpand?: boolean;
}) {
  const [value, setValue] = useState<string>(defaultValue || '');
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);
  function autosize(target: HTMLTextAreaElement) {
    target.style.height = 'auto';
    target.style.height = Math.min(target.scrollHeight, 200) + 2 + 'px';
  }
  return <textarea className={`resize-none rounded-md border-gray-200 bg-gray-50 p-0 px-1 font-mono text-sm text-black hover:z-10 hover:border-gray-400 hover:shadow-lg focus:z-20 focus:border-gray-200 focus:shadow-lg ${error ? 'bg-red-50 focus:border-red-500 focus:ring-red-500' : ''} ${autoexpand ? 'overflow-hidden' : ''} ${className}`} value={value} rows={1} placeholder={placeholder} onFocus={e => {
    if (autoexpand) {
      autosize(e.target);
    }
  }} onChange={async e => {
    setValue(e.target.value);
    if (autoexpand) {
      autosize(e.target);
    }
    if (validate) {
      const err = await validate(e.target.value);
      if (err) setError(err);else setError(null);
    }
  }} onBlur={async e => {
    e.target.style.height = 'auto';
    if (value == (defaultValue || '')) {
      return;
    }
    const err = validate && (await validate(value));
    if (err) {
      e.target.focus();
      setError(err);
      toast.error(err);
      return;
    }
    setError(null);
    await updateAction(value).then(r => {
      setValue(r);
      onBlur && onBlur();
    }).catch(error => {
      setError(error.message);
      e.target.focus();
    });
  }} onKeyDown={e => {
    if (e.key === 'Escape') {
      setValue(defaultValue || '');
      (e.target as HTMLInputElement).blur();
    }
  }} onMouseEnter={e => {
    if (autoexpand) {
      autosize(e.target as HTMLTextAreaElement);
    }
  }} onMouseLeave={e => {
    if (autoexpand && document.activeElement !== e.target) {
      ;
      (e.target as HTMLTextAreaElement).style.height = 'auto';
    }
  }} data-sentry-component="InstantTextAreaField" data-sentry-source-file="InstantFields.tsx" />;
}
export function InstantTextField({
  type = 'text',
  defaultValue,
  updateAction,
  placeholder = ' - ',
  label,
  vertical = true,
  inline = false,
  className,
  validate,
  onBlur,
  autoFocus,
  trim = true,
  showAlways = true,
  baseClassName,
  labelClassName,
  disabled
}: InstantFieldProps<string> & {
  type?: 'text' | 'number' | 'email' | 'tel';
  inline?: boolean;
  trim?: boolean;
  showAlways?: boolean;
  baseClassName?: string;
  labelClassName?: string;
}) {
  const [isEditing, setIsEditing] = useState(showAlways ? true : false);
  const [value, setValue] = useState<string>(defaultValue || '');
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);
  const submit = async (v: string, refocus: () => void) => {
    const newValue = trim ? v.trim() : v;
    setValue(newValue);
    if (newValue == (defaultValue || '')) {
      onBlur && onBlur();
      !showAlways && setIsEditing(false);
      return;
    }
    const err = validate && (await validate(newValue));
    if (err) {
      refocus();
      setError(null);
      toast.error(err);
      setValue(defaultValue || '');
      return;
    }
    setError(null);
    await updateAction(newValue).then(r => {
      setValue(r);
      onBlur && onBlur();
      !showAlways && setIsEditing(false);
    }).catch(e => {
      setError(e.message);
      refocus();
    });
  };
  return <div className={cn('flex', inline && 'inline-flex', vertical && 'flex-col', baseClassName)} data-sentry-component="InstantTextField" data-sentry-source-file="InstantFields.tsx">
      {label && <Label className={cn('pb-2', labelClassName)}>{label}</Label>}
      {isEditing ? <Input type={type} error={!!error} className={cn(!showAlways && '-mx-3 -my-1 px-3 py-1', !vertical && label ? '' : 'grow', inline && '-m-0 -mx-1 p-0 px-1 font-mono', className)} value={value} placeholder={placeholder} autoFocus={autoFocus || !showAlways} size={inline ? value?.length || placeholder?.length || 3 : undefined} onChange={async e => {
      setValue(e.target.value);
      if (validate) {
        const err = await validate(e.target.value);
        if (err) setError(err);else setError(null);
      }
      if (inline) e.target.size = e.target.value.length || 4;
    }} onKeyDown={e => {
      if (e.key === 'Enter') {
        ;
        (e.target as HTMLInputElement).blur();
      }
      if (e.key === 'Escape') {
        ;
        (e.target as HTMLInputElement).value = defaultValue || '';
        setValue(defaultValue || '');
        setError(null);
        (e.target as HTMLInputElement).blur();
      }
    }} onBlur={e => {
      submit(e.target.value, () => e.target.focus());
    }} /> : <button className="group flex items-center gap-2" onClick={() => setIsEditing(true)} disabled={disabled}>
          <p className={`text-start ${value ? '' : 'text-gray-500'}`}>
            {value || placeholder}
          </p>
          <Pencil className="size-4 shrink-0 text-gray-500 opacity-0 transition-all group-hover:group-enabled:opacity-100" />
        </button>}
    </div>;
}