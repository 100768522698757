'use client';

import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import React, { startTransition } from 'react';
import { useProgress } from 'react-transition-progress';
const slashedProtocols = /https?|ftp|gopher|file/;
function stringifyUrlQueryParam(param: unknown): string {
  if (typeof param === 'string' || typeof param === 'number' && !isNaN(param) || typeof param === 'boolean') {
    return String(param);
  } else {
    return '';
  }
}
export function urlQueryToSearchParams(urlQuery: Record<string, string | number | boolean | Array<string | number | boolean>>): URLSearchParams {
  const result = new URLSearchParams();
  Object.entries(urlQuery).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => result.append(key, stringifyUrlQueryParam(item)));
    } else {
      result.set(key, stringifyUrlQueryParam(value));
    }
  });
  return result;
}
export function formatUrl(urlObj: {
  auth?: string;
  hostname?: string;
  protocol?: string;
  pathname?: string;
  hash?: string;
  query?: Record<string, string | number | boolean | Array<string | number | boolean>>;
  host?: string;
  port?: string;
  slashes?: boolean;
  search?: string;
}) {
  let {
    auth,
    hostname
  } = urlObj;
  let protocol = urlObj.protocol || '';
  let pathname = urlObj.pathname || '';
  let hash = urlObj.hash || '';
  let query = urlObj.query || '';
  let host: string | false = false;
  auth = auth ? encodeURIComponent(auth).replace(/%3A/i, ':') + '@' : '';
  if (urlObj.host) {
    host = auth + urlObj.host;
  } else if (hostname) {
    host = auth + (~hostname.indexOf(':') ? `[${hostname}]` : hostname);
    if (urlObj.port) {
      host += ':' + urlObj.port;
    }
  }
  if (query && typeof query === 'object') {
    query = String(urlQueryToSearchParams(query));
  }
  let search = urlObj.search || query && `?${query}` || '';
  if (protocol && !protocol.endsWith(':')) protocol += ':';
  if (urlObj.slashes || (!protocol || slashedProtocols.test(protocol)) && host !== false) {
    host = '//' + (host || '');
    if (pathname && pathname[0] !== '/') pathname = '/' + pathname;
  } else if (!host) {
    host = '';
  }
  if (hash && hash[0] !== '#') hash = '#' + hash;
  if (search && search[0] !== '?') search = '?' + search;
  pathname = pathname.replace(/[?#]/g, encodeURIComponent);
  search = search.replace('#', '%23');
  return `${protocol}${host}${pathname}${search}${hash}`;
}

// Copied from  https://github.com/vercel/next.js/blob/canary/packages/next/src/client/link.tsx#L180-L191
function isModifiedEvent(event: React.MouseEvent): boolean {
  const eventTarget = event.currentTarget as HTMLAnchorElement | SVGAElement;
  const target = eventTarget.getAttribute('target');
  return target && target !== '_self' || event.metaKey || event.ctrlKey || event.shiftKey || event.altKey ||
  // triggers resource download
  event.nativeEvent && event.nativeEvent.which === 2;
}

/**
 * A custom Link component that wraps Next.js's next/link component.
 */
export function Link({
  href,
  children,
  replace,
  scroll,
  onClick,
  ...rest
}: Parameters<typeof NextLink>[0]) {
  const router = useRouter();
  const startProgress = useProgress();
  return <NextLink href={href} onClick={e => {
    onClick?.(e);
    if (isModifiedEvent(e)) return;
    e.preventDefault();
    startTransition(() => {
      startProgress();
      // @ts-expect-error
      const url = typeof href === 'string' ? href : formatUrl(href);
      if (replace) {
        router.replace(url, {
          scroll
        });
      } else {
        router.push(url, {
          scroll
        });
      }
    });
  }} {...rest} data-sentry-element="NextLink" data-sentry-component="Link" data-sentry-source-file="Link.tsx">
      {children}
    </NextLink>;
}