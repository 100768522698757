'use client';

import { useTheme } from 'next-themes';
import * as React from 'react';
import { Button, type ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { LoaderCircle, Moon, Sun, SunMoon } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip';
export function ThemeToggle({
  className,
  ...props
}: Omit<ButtonProps, 'onClick'>) {
  const {
    setTheme,
    theme,
    systemTheme
  } = useTheme();
  const [_, startTransition] = React.useTransition();
  return <TooltipProvider delayDuration={0} data-sentry-element="TooltipProvider" data-sentry-component="ThemeToggle" data-sentry-source-file="theme-toggle.tsx">
      <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="theme-toggle.tsx">
        <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="theme-toggle.tsx">
          <Button variant="ghost" size="icon" onClick={e => {
          startTransition(() => {
            let newTheme = 'system';
            if (theme === 'system') {
              newTheme = systemTheme === 'dark' ? 'light' : 'dark';
            } else if (theme === 'light') {
              newTheme = systemTheme === 'light' ? 'system' : 'dark';
            } else if (theme === 'dark') {
              newTheme = systemTheme === 'dark' ? 'system' : 'light';
            }
            setTheme(newTheme);
          });
        }} className={cn(className, 'group')} {...props} data-sentry-element="Button" data-sentry-source-file="theme-toggle.tsx">
            {!theme ? <LoaderCircle key="theme-loader" strokeWidth={1} className="size-4 animate-spin" /> : theme === 'light' ? <Sun key="sun" className="size-4 transition-transform duration-500 group-hover:rotate-45" /> : theme === 'dark' ? <Moon key="moon" className="size-4 rotate-12 transition-transform duration-500 group-hover:-rotate-12" /> : theme === 'system' ? <SunMoon key="sun-moon" className="size-4 transition-transform duration-500 group-hover:-rotate-45" /> : null}
            <span className="sr-only">Toggle theme</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right" data-sentry-element="TooltipContent" data-sentry-source-file="theme-toggle.tsx">{`${theme?.charAt(0)?.toUpperCase()}${theme?.slice(1)}`}</TooltipContent>
      </Tooltip>
    </TooltipProvider>;
}