'use client';

import { Sheet, SheetContent } from '@/components/ui/sheet';
import { useSidebar } from '@/lib/hooks/use-sidebar';
import { cn } from '@/lib/utils';
import { useEffect } from 'react';
import { SupabaseChats } from './fetch-chats-server';
import { SidebarToggleButton } from './toggle';
export function SidebarWrapper({
  chats,
  children
}: {
  chats: SupabaseChats;
  children: React.ReactNode;
}) {
  const {
    isSidebarOpen,
    toggleSidebar,
    isMobile,
    setInitialChats
  } = useSidebar();
  useEffect(() => {
    setInitialChats(chats);
  }, [chats, setInitialChats]);
  return <>
      <Sheet open={isSidebarOpen && isMobile} onOpenChange={toggleSidebar} data-sentry-element="Sheet" data-sentry-source-file="wrapper.tsx">
        <SheetContent side="left" className="border-none bg-transparent p-2 md:hidden" withCloseButton={false} data-sentry-element="SheetContent" data-sentry-source-file="wrapper.tsx">
          {children}
        </SheetContent>
      </Sheet>
      {!isSidebarOpen && <div className="absolute left-4 top-4 z-20 md:hidden">
          <SidebarToggleButton className="backdrop-blur-lg" />
        </div>}
      <div data-state={isSidebarOpen ? 'open' : 'closed'} className={cn('hidden h-full transition-[width] duration-300 md:flex', isSidebarOpen ? 'w-64' : 'w-14')}>
        {children}
      </div>
    </>;
}