'use client';

import { Link } from '@/components/Link';
import { Button } from '@/components/ui/button';
import { cn, formatDate } from '@/lib/utils';
import { motion } from 'framer-motion';
import { usePathname, useRouter } from 'next/navigation';
// import { ChatShareDialog } from '../chat-share-dialog'
import { Form } from '@/components/forms/Form';
import SubmitButton from '@/components/forms/SubmitButton';
import { InstantTextField } from '@/components/inputs/InstantFields';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { DropdownMenu, DropdownMenuContent, DropdownMenuDescription, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuPortal, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { IconSpinner } from '@/components/ui/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useSidebar } from '@/lib/hooks/use-sidebar';
import { createBrowserSupabase, useUser } from '@/lib/supabase/browser';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@sentry/nextjs';
import { Ellipsis, Trash } from 'lucide-react';
import { useState, useTransition } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
import { SupabaseChat } from './fetch-chats-server';
function DeleteChatDialog({
  open,
  setOpen,
  chatId
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  chatId: string;
}) {
  const [isRemovePending, startRemoveTransition] = useTransition();
  const pathname = usePathname();
  const router = useRouter();
  const {
    refreshChats
  } = useSidebar();
  return <AlertDialog open={open} onOpenChange={setOpen} data-sentry-element="AlertDialog" data-sentry-component="DeleteChatDialog" data-sentry-source-file="chat-button.tsx">
      <AlertDialogContent data-sentry-element="AlertDialogContent" data-sentry-source-file="chat-button.tsx">
        <AlertDialogHeader data-sentry-element="AlertDialogHeader" data-sentry-source-file="chat-button.tsx">
          <AlertDialogTitle data-sentry-element="AlertDialogTitle" data-sentry-source-file="chat-button.tsx">Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription data-sentry-element="AlertDialogDescription" data-sentry-source-file="chat-button.tsx">
            This will permanently delete this chat and all of its messages from
            our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter data-sentry-element="AlertDialogFooter" data-sentry-source-file="chat-button.tsx">
          <AlertDialogCancel disabled={isRemovePending} data-sentry-element="AlertDialogCancel" data-sentry-source-file="chat-button.tsx">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction variant={'destructive'} disabled={isRemovePending} onClick={event => {
          event.preventDefault();
          startRemoveTransition(async () => {
            await Promise.all([createBrowserSupabase().from('chats').delete().eq('id', chatId).throwOnError(), createBrowserSupabase().from('messages').update({
              is_removed: true,
              content: null
            }).eq('chat_id', chatId).throwOnError()]).then(r => {
              toast.success('Chat deleted');
              setOpen(false);
              if (pathname.includes(chatId)) {
                router.push('/');
              }
              refreshChats();
            }).catch(e => {
              toast.error('There was an error deleting the chat');
              captureException(e);
            });
          });
        }} data-sentry-element="AlertDialogAction" data-sentry-source-file="chat-button.tsx">
            {isRemovePending && <IconSpinner className="mr-2 animate-spin" />}
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>;
}
const formSchema = z.object({
  title: z.string().max(30).optional(),
  description: z.string().max(100).optional()
});
type EditChatFormValues = z.infer<typeof formSchema>;
function EditChatDialog({
  open,
  setOpen,
  chat
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  chat: SupabaseChat;
}) {
  const {
    refreshChats
  } = useSidebar();
  const form = useForm<EditChatFormValues>({
    defaultValues: {
      title: chat.title,
      description: chat.description
    },
    resolver: zodResolver(formSchema)
  });
  return <AlertDialog open={open} onOpenChange={setOpen} data-sentry-element="AlertDialog" data-sentry-component="EditChatDialog" data-sentry-source-file="chat-button.tsx">
      <AlertDialogContent data-sentry-element="AlertDialogContent" data-sentry-source-file="chat-button.tsx">
        <Form form={form} onSubmit={async values => {
        const {
          data,
          error
        } = await createBrowserSupabase().from('chats').update({
          title: values.title,
          description: values.description
        }).eq('id', chat.id);
        if (error) {
          toast.error(error.message);
          return;
        }
        setOpen(false);
        refreshChats();
      }} data-sentry-element="Form" data-sentry-source-file="chat-button.tsx" />
        <AlertDialogHeader data-sentry-element="AlertDialogHeader" data-sentry-source-file="chat-button.tsx">
          <AlertDialogTitle data-sentry-element="AlertDialogTitle" data-sentry-source-file="chat-button.tsx">Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription data-sentry-element="AlertDialogDescription" data-sentry-source-file="chat-button.tsx">
            This will permanently delete this chat and all of its messages from
            our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter data-sentry-element="AlertDialogFooter" data-sentry-source-file="chat-button.tsx">
          <AlertDialogCancel disabled={form.formState.isSubmitting} data-sentry-element="AlertDialogCancel" data-sentry-source-file="chat-button.tsx">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction asChild data-sentry-element="AlertDialogAction" data-sentry-source-file="chat-button.tsx">
            <SubmitButton form={form} label="Save" submittingLabel="Saving..." data-sentry-element="SubmitButton" data-sentry-source-file="chat-button.tsx" />
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>;
}
function ChatButtonActions({
  chat,
  children
}: {
  chat: SupabaseChat;
  children?: React.ReactNode;
}) {
  const {
    data: user
  } = useUser();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const isAdmin = !!chat.chat_participants.find(p => p.user_id === user?.id && p.is_admin);
  return <>
      <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-source-file="chat-button.tsx">
        <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="chat-button.tsx">{children}</DropdownMenuTrigger>
        <DropdownMenuContent className="w-72" side="right" align="start" collisionPadding={12} data-sentry-element="DropdownMenuContent" data-sentry-source-file="chat-button.tsx">
          <DropdownMenuLabel data-sentry-element="DropdownMenuLabel" data-sentry-source-file="chat-button.tsx">
            <InstantTextField defaultValue={chat.title || ''} showAlways={false} inline autoFocus trim placeholder="Chat title" className="text-sm" updateAction={async (v: string) => {
            const r = await createBrowserSupabase().from('chats').update({
              title: v
            }).eq('id', chat.id).select('title').single();
            if (r.error) {
              throw r.error;
            }
            return r.data.title || '';
          }} data-sentry-element="InstantTextField" data-sentry-source-file="chat-button.tsx" />
          </DropdownMenuLabel>
          {chat.description && <DropdownMenuDescription>
              <InstantTextField defaultValue={chat.description || ''} showAlways={false} inline trim placeholder="Chat description" className="text-xs text-muted-foreground" updateAction={async (v: string) => {
            const r = await createBrowserSupabase().from('chats').update({
              description: v
            }).eq('id', chat.id).select('description').single();
            if (r.error) {
              throw r.error;
            }
            return r.data.description || '';
          }} />
            </DropdownMenuDescription>}
          <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="chat-button.tsx" />
          <DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="chat-button.tsx">
            {/* <DropdownMenuItem onClick={() => setEditDialogOpen(true)}>
              Edit
             </DropdownMenuItem> */}
            <DropdownMenuSub data-sentry-element="DropdownMenuSub" data-sentry-source-file="chat-button.tsx">
              <DropdownMenuSubTrigger data-sentry-element="DropdownMenuSubTrigger" data-sentry-source-file="chat-button.tsx">Share</DropdownMenuSubTrigger>
              <DropdownMenuPortal data-sentry-element="DropdownMenuPortal" data-sentry-source-file="chat-button.tsx">
                <DropdownMenuSubContent data-sentry-element="DropdownMenuSubContent" data-sentry-source-file="chat-button.tsx">
                  <DropdownMenuItem data-sentry-element="DropdownMenuItem" data-sentry-source-file="chat-button.tsx">Comming soon...</DropdownMenuItem>
                  {/* <DropdownMenuItem>Email</DropdownMenuItem>
                   <DropdownMenuItem>Message</DropdownMenuItem>
                   <DropdownMenuSeparator />
                   <DropdownMenuItem>More...</DropdownMenuItem> */}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="chat-button.tsx" />
          <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="chat-button.tsx">
            <TooltipTrigger className="w-full" data-sentry-element="TooltipTrigger" data-sentry-source-file="chat-button.tsx">
              <DropdownMenuItem disabled={!isAdmin} className="text-red-500 hover:!bg-red-500/10 hover:!text-red-600" onClick={() => setDeleteDialogOpen(true)} data-sentry-element="DropdownMenuItem" data-sentry-source-file="chat-button.tsx">
                Delete chat
                <Trash size={14} data-sentry-element="Trash" data-sentry-source-file="chat-button.tsx" />
              </DropdownMenuItem>
            </TooltipTrigger>
            {!isAdmin && <TooltipContent side="right" className="max-w-48">
                Only admins can delete chats
              </TooltipContent>}
          </Tooltip>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteChatDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} chatId={chat.id} data-sentry-element="DeleteChatDialog" data-sentry-source-file="chat-button.tsx" />

      {/* <EditChatDialog
        open={editDialogOpen}
        setOpen={setEditDialogOpen}
        chat={chat}
       /> */}
    </>;
}
function FadeInText({
  text,
  duration = 1,
  delay = 0
}: {
  text: string;
  duration?: number;
  delay?: number;
}) {
  return text.split('').map((character, index) => <motion.span key={index} variants={{
    initial: {
      opacity: 0,
      x: -100
    },
    animate: {
      opacity: 1,
      x: 0
    }
  }} initial={'initial'} animate={'animate'} transition={{
    duration: 5 * duration / text.length,
    ease: 'easeIn',
    delay: delay + duration * index / text.length,
    staggerChildren: duration * index / text.length
  }}>
      {character}
    </motion.span>);
}
export function ChatButton({
  chat,
  isActive,
  shouldAnimate
}: {
  chat: SupabaseChat;
  isActive: boolean;
  shouldAnimate: boolean;
}) {
  const {
    toggleSidebar,
    isMobile,
    isSidebarOpen
  } = useSidebar();
  return isSidebarOpen ? <Button variant={'ghost'} className={cn('group flex h-20 w-full flex-row justify-between gap-0 rounded-none px-3 py-0 transition-colors hover:bg-zinc-200/40 group-first:rounded-t-lg dark:hover:bg-zinc-300/10', isActive && 'bg-zinc-200 font-semibold dark:bg-zinc-700')} asChild>
      <Link href={`/chat/${chat.id}`} onClick={() => isMobile && toggleSidebar()}>
        <div className="flex flex-1 flex-col items-start justify-center self-stretch overflow-hidden break-all gradient-mask-r-80">
          <p className="w-full select-none whitespace-pre" title={chat.title || 'Unnamed chat'}>
            {shouldAnimate && chat.title ? <FadeInText text={(chat.icon + '  ' + chat.title).trim()} duration={1} /> : (chat.icon + '  ' + chat.title).trim() || 'Unnamed chat'}
          </p>
          <p className="w-full text-xs font-medium text-muted-foreground">
            {shouldAnimate ? <FadeInText text={chat.description} delay={1} /> : chat.description}
          </p>
          <p className="pt-1 text-xs font-normal text-muted-foreground">
            {formatDate(new Date(chat.messages.slice(-1)[0]?.created_at))}
          </p>
        </div>
        <div onClick={e => e.stopPropagation()}>
          <ChatButtonActions chat={chat}>
            <Button variant="ghost" size={'icon'} className="group-hover:w-8 group-hover:px-1 data-[state=open]:w-8 data-[state=open]:px-1 sm:w-0 sm:px-0 sm:transition-all">
              <Ellipsis size={14} />
            </Button>
          </ChatButtonActions>
        </div>
      </Link>
    </Button> : isActive ? <ChatButtonActions chat={chat}>
      <Button variant="subtle" size={'icon'} className="my-1 size-9 bg-zinc-200 font-semibold dark:bg-zinc-700">
        <span className="hidden group-hover:inline">
          <Ellipsis size={18} />
        </span>
        <span className="inline-block group-hover:hidden">
          {chat.icon || chat.title?.[0] || '💬'}
        </span>
      </Button>
    </ChatButtonActions> : <Button variant={'ghost'} size={'icon'} className={cn('group my-1 size-9')} asChild>
      <Link href={`/chat/${chat.id}`} onClick={() => isMobile && toggleSidebar()}>
        {chat.icon || chat.title?.[0] || '💬'}
      </Link>
    </Button>;
}