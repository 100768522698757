'use client';

import { Button, type ButtonProps } from '@/components/ui/button';
import { useSidebar } from '@/lib/hooks/use-sidebar';
import { PanelLeftClose, PanelLeftOpen } from 'lucide-react';
export function SidebarToggleButton(props: Omit<ButtonProps, 'onClick'>) {
  const {
    isSidebarOpen,
    toggleSidebar
  } = useSidebar();
  return <Button variant={'ghost'} size={'icon'} {...props} onClick={toggleSidebar} data-sentry-element="Button" data-sentry-component="SidebarToggleButton" data-sentry-source-file="toggle.tsx">
      {isSidebarOpen ? <PanelLeftClose size={18} /> : <PanelLeftOpen size={18} />}
      <span className="sr-only">Toggle Sidebar</span>
    </Button>;
}