'use client';

import { Link } from '@/components/Link';
import { ThemeToggle } from '@/components/theme-toggle';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { useSidebar } from '@/lib/hooks/use-sidebar';
import { useUser } from '@/lib/supabase/browser';
import { cn, formatDollars } from '@/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { Bug, Plus, TriangleAlert, UserRound } from 'lucide-react';
import { Route } from 'next';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { ChatButton } from './chat-button';
import { SidebarToggleButton } from './toggle';
function SidebarTop() {
  const {
    isSidebarOpen
  } = useSidebar();
  const pathname = usePathname();
  return <div className={cn('flex items-center gap-2 pb-4', isSidebarOpen ? 'flex-row' : 'flex-col')} data-sentry-component="SidebarTop" data-sentry-source-file="content.tsx">
      <SidebarToggleButton data-sentry-element="SidebarToggleButton" data-sentry-source-file="content.tsx" />
      {pathname === '/' ? <Button size={'icon'} className="grow" disabled>
          <Plus size={18} />
          {isSidebarOpen ? 'New Chat' : ''}
        </Button> : <Button size={'icon'} className="grow" asChild>
          <Link href="/">
            <Plus size={18} />
            {isSidebarOpen ? 'New Chat' : ''}
          </Link>
        </Button>}
    </div>;
}
function ChatList() {
  const {
    chats,
    isSidebarOpen
  } = useSidebar();
  const pathname = usePathname();
  return chats?.length ? <div className={cn('-mx-2.5 flex flex-col divide-y divide-zinc-400/50 overflow-auto px-2.5 gradient-mask-b-90', isSidebarOpen ? '' : 'items-center overflow-x-visible pb-12')}>
      <AnimatePresence>
        {chats.map((chat, index) => {
        const isActive = pathname.includes(chat.id);
        const shouldAnimate = index === 0 && chat.messages.length <= 2;
        return <motion.div id={chat.id + 'sidebar-button'} key={chat.id} className="group text-clip" initial={shouldAnimate ? {
          height: 0,
          opacity: 0
        } : undefined} animate={{
          height: 'auto',
          opacity: 1
        }} exit={{
          opacity: 0,
          height: 0
        }} transition={{
          duration: 0.25,
          ease: 'easeIn'
        }}>
              <ChatButton key={chat.id} shouldAnimate={shouldAnimate} chat={chat} isActive={isActive} />
            </motion.div>;
      })}
      </AnimatePresence>

      {isSidebarOpen && <div className="grid place-content-center gap-3 pb-20 pt-12 text-sm font-medium text-muted-foreground">
          That&apos;s all the chats you have
          {pathname !== '/' && <Button asChild>
              <Link href="/">
                <Plus size={18} />
                Start a new chat
              </Link>
            </Button>}
        </div>}
    </div> : isSidebarOpen && <div className="p-8 text-center">
        <p className="text-sm text-muted-foreground">No chat history</p>
      </div>;
}
function SidebarBottom() {
  const user = useUser();
  const {
    isSidebarOpen
  } = useSidebar();
  const activeBillingAccount = user.data?.user_billing_accounts.find(b => b.is_active);
  return <div className="mt-auto flex flex-col duration-300 animate-in fade-in-0" data-sentry-component="SidebarBottom" data-sentry-source-file="content.tsx">
      {isSidebarOpen ? <>
          <Separator className="mb-4 bg-muted-foreground/50" />

          <Link href={'/settings/account' as Route} className="group my-1 shrink self-stretch hover:underline">
            {user.data?.username ? user.data?.username : <button className="flex items-center gap-2">
                <TriangleAlert className="shrink-0 text-yellow-600" />
                Your account is not set up
              </button>}
          </Link>
          <p className="mb-2 flex text-sm text-muted-foreground">
            <span className="whitespace-pre">Credits available: </span>

            <Link href={`/settings/billing-accounts/${activeBillingAccount?.billing_account_id}`} className={cn('font-semibold underline', (activeBillingAccount?.billing_account?.balance || 0) > 0.5 ? 'text-green-500' : (activeBillingAccount?.billing_account?.balance || 0) > 0 ? 'text-amber-500' : 'text-destructive')} title={`$${activeBillingAccount?.billing_account?.balance}`}>
              {activeBillingAccount?.billing_account?.balance ? formatDollars(activeBillingAccount?.billing_account?.balance) : 'N/A'}
            </Link>
          </p>
        </> : <Button variant={'ghost'} size={'icon'} className="size-9 self-start" asChild>
          <Link href="/settings/account">
            <UserRound className="size-4" />
          </Link>
        </Button>}
      <div className={cn('mt-2 flex flex-col justify-between gap-2', isSidebarOpen && 'flex-row-reverse')}>
        <ThemeToggle className={cn('mt-auto size-9 shrink-0 self-start p-2 transition-all')} data-sentry-element="ThemeToggle" data-sentry-source-file="content.tsx" />
        {isSidebarOpen && <Separator orientation="vertical" className="bg-white/10" />}
        <Script async src="https://tally.so/widgets/embed.js" data-sentry-element="Script" data-sentry-source-file="content.tsx"></Script>
        <Button variant={'ghost'} size={isSidebarOpen ? 'default' : 'icon'} className={cn('grow', !isSidebarOpen && 'size-9')} data-tally-open="n0JeQN" data-tally-hide-title="1" data-email={user.data?.email} data-tally-auto-close="2000" data-sentry-element="Button" data-sentry-source-file="content.tsx">
          <Bug className="size-4 shrink-0" data-sentry-element="Bug" data-sentry-source-file="content.tsx" />
          {isSidebarOpen && 'Feedback'}
        </Button>
      </div>
    </div>;
}
function SidebarBody() {
  const user = useUser();
  const {
    isSidebarOpen,
    isLoading: isSidebarLoading
  } = useSidebar();
  if (!user.data && user.isFetching || isSidebarLoading) {
    return <div className="flex h-full flex-col gap-2">
        {isSidebarOpen ? <>
            <div className="flex items-stretch justify-start gap-4 self-stretch pb-6">
              <SidebarToggleButton className="size-9 self-start" />
              <Skeleton className="grow" />
            </div>
            <div className="flex flex-col gap-2">
              {Array.from({
            length: 5
          }).map((_, i) => <Skeleton key={i} className={cn('h-16 w-full shrink-0 animate-pulse rounded-lg bg-input')} style={{
            animationDelay: `${i * 0.1 - 0.5}s`
          }} />)}
            </div>
            <div className="mt-auto flex flex-col gap-2">
              <Skeleton className="h-6 w-48" />
              <Skeleton className="h-4 w-24" />
              <div className="flex gap-2 pt-4">
                <Skeleton className="grow" />
                <Separator orientation="vertical" className="bg-white/10" />
                <Skeleton className="size-9" />
              </div>
              <Skeleton className="h-9" />
            </div>
          </> : <>
            <SidebarToggleButton className="size-9 self-start" />
            <Skeleton className="size-9" />
            <Separator orientation="horizontal" className="bg-white/10" />
            <Skeleton className="size-9" />
            <Skeleton className="size-9" />
            <Skeleton className="size-9" />
            <Skeleton className="size-9" />
            <Skeleton className="size-9" />
            <Skeleton className="mt-auto size-9" />
            <Skeleton className="size-9" />
            <Skeleton className="size-9" />
          </>}
      </div>;
  }
  if (!user.data) {
    return <>
        <SidebarToggleButton className="size-9 self-start" />
        {isSidebarOpen && <div className="flex min-w-36 grow flex-col items-center justify-start gap-4 px-2 pt-28 duration-500 animate-in fade-in-0">
            <p className="w-full text-center text-sm text-muted-foreground">
              Sign in to see your chat history
            </p>
          </div>}
        <ThemeToggle className={cn('mt-auto size-9 shrink-0 self-start p-2 transition-all')} />
      </>;
  }
  return <>
      <SidebarTop data-sentry-element="SidebarTop" data-sentry-source-file="content.tsx" />
      <ChatList data-sentry-element="ChatList" data-sentry-source-file="content.tsx" />
      <SidebarBottom data-sentry-element="SidebarBottom" data-sentry-source-file="content.tsx" />
    </>;
}
export function SidebarContent() {
  const {
    isSidebarOpen
  } = useSidebar();
  return <div className={cn('flex size-full flex-col items-stretch overflow-hidden rounded-2xl bg-accent p-4 transition-all duration-300', !isSidebarOpen && 'rounded-xl p-2.5')} data-sentry-component="SidebarContent" data-sentry-source-file="content.tsx">
      <SidebarBody data-sentry-element="SidebarBody" data-sentry-source-file="content.tsx" />
    </div>;
}